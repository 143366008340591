<!--
 * @Author: 智客云网络科技
 * @Date: 2021-10-16 21:02:43
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:11:34
 * @Description: 创建 修改 组织
 * @FilePath: \src\views\Frame\Auth\Organization\NUOrganization.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :model="m" :inline="true" ref="m" label-width="100px">
          <el-form-item label="上级组织：" style="width: 90%">
            <el-select size="small" v-model="m.parent_id">
              <el-option label="根组织" :value="'0'"></el-option>
              <el-option
                v-for="item in topOrganizationList"
                :key="item.korganization_id"
                :label="item.kor_name"
                :value="item.korganization_id"
              >
                <i :class="item.icon"></i> {{ item.kor_name }}
              </el-option>
            </el-select>
            <el-tooltip
              class="item"
              effect="dark"
              content="刷新列表"
              placement="top-start"
            >
              <el-button
                size="small"
                icon="el-icon-refresh"
                @click="_GetAllTopOrganization()"
              ></el-button>
            </el-tooltip>
          </el-form-item>

          <el-form-item label="组织名称：" style="width: 90%">
            <el-input
              size="small"
              v-model="m.name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="显示排序：" style="width: 45%">
            <el-input-number
              v-model="m.sort"
              size="small"
              :min="1"
              :max="999999"
            ></el-input-number>
          </el-form-item>

          <el-form-item label="组织状态：" style="width: 30%">
            <el-switch
              size="small"
              v-model="m.status"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <div class="from-notes-box">
            <el-form-item label="描述：" style="width: 90%; display: block">
              <el-input
                size="small"
                v-model="m.notes"
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>

        <el-divider></el-divider>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_CreateNewOrganization()"
          v-if="id == 0"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdateOrganization()"
          v-else
          >保存</el-button
        >
        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>
    </div>
  </el-dialog>
</template>
<script>
import {
  createNewOrganization,
  updateOrganization,
  getAllTopOrganization,
  getOrganizationInfo,
} from "../api"; //页面专有接口
export default {
  data() {
    return {
      m: {
        parent_id: "0",
        name: "",
        sort: 1,
        status: 1,
        notes: "",
      },
      id: 0, //
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      topOrganizationList: [], //顶级组织列表
    };
  },
  methods: {
    //打开组件
    open(id) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this._GetAllTopOrganization(); //获取所有顶级组织列表
      this.$nextTick(() => {
        if (id === 0) {
          //0 新建
          this.title = "新建组织";
          this.loading = false;
        } else {
          //修改
          this.title = "修改组织";
          this._GetOrganizationInfo(id);
        }
      });
    },

    //创建新组织
    _CreateNewOrganization() {
      var m = this.m;
      createNewOrganization({
        parent_id: m.parent_id, //上级组织ID
        name: m.name, //组织名称
        sort: m.sort, //排序
        status: m.status, //状态
        notes: m.notes, //描述
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改组织
    _UpdateOrganization() {
      var m = this.m;
      updateOrganization({
        kor_id: this.id, //组织ID
        parent_id: m.parent_id, //上级组织ID
        name: m.name, //组织名称
        sort: m.sort, //排序
        status: m.status, //状态
        notes: m.notes, //描述
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取顶级组织列表
    _GetAllTopOrganization() {
      getAllTopOrganization({
        ban_kor_id: this.id,
      })
        .then((res) => {
          this.topOrganizationList = Object.values(res.data); //写入组织列表
          this.m.parent_id = "0"; //重置上级组织
        })
        .catch((err) => {
          if (err.code !== 103043) {
            this.isShow = false;
            this.$utils.error(err.err_msg);
          }
        });
    },

    //获取指定组织参数
    _GetOrganizationInfo(id) {
      getOrganizationInfo({
        kor_id: id,
      })
        .then((res) => {
          let m = this.m;
          let d = res.data;
          m.parent_id = d.kor_parent_id; //上级组织ID
          m.name = d.kor_name; //组织名称
          m.sort = d.kor_sort; //排序
          m.status = d.kor_status; //状态
          m.notes = d.kor_notes; //描述
          this.loading = false;
        })
        .catch((err) => {
          this.isShow = false;
          this.$utils.error(err.err_msg);
        });
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },
  },
};
</script>
