/*
 * @Author: 智客云网络科技
 * @Date: 2021-10-16 16:46:06
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:06:13
 * @Description: 页面专属接口管理
 * @FilePath: \src\views\Frame\Auth\api.js
 */

import http from "@/api";

/**************************** 组织操作 ****************************/

// 创建新组织
export const createNewOrganization = (params) =>
  http.post("/v1/Auth/CreateOrganization", params);

// 修改组织
export const updateOrganization = (params) =>
  http.put("/v1/Auth/UpdateOrganization", params);

// 删除组织
export const delOrganization = (params) =>
  http.delete("/v1/Auth/DelOrganization", params);

// 获取组织表格
export const getOrganizationTable = (params) =>
  http.get("/v1/Auth/GetOrganizationTable", params);

// 获取根组织列表
export const getAllTopOrganization = (params) =>
  http.get("/v1/Auth/GetAllTopOrganization", params);

// 根据ID查询指定组织参数
export const getOrganizationInfo = (params) =>
  http.get("/v1/Auth/GetOrganizationInfoById", params);

// 获取组织ID列表
export const getKorIdList = () => http.get("/v1/Auth/GetOrganizationIdList");

/**************************** 权限操作 ****************************/

// 创建新权限
export const createNewRight = (params) =>
  http.post("/v1/Auth/CreateRight", params);

// 修改权限
export const updateRight = (params) => http.put("/v1/Auth/UpdateRight", params);

// 删除权限
export const delRight = (params) => http.delete("/v1/Auth/DelRight", params);

// 获取权限信息
export const getRightInfo = (params) =>
  http.get("/v1/Auth/GetRightInfoById", params);

// 获取权限表格
export const getRightTable = (params) =>
  http.get("/v1/Auth/GetRightTable", params);

/**************************** 权限分组操作 ****************************/

// 创建新权限分组
export const createNewRightGroup = (params) =>
  http.post("/v1/Auth/CreateRightGroup", params);

// 修改权限分组
export const updateRightGroup = (params) =>
  http.put("/v1/Auth/UpdateRightGroup", params);

// 删除权限分组
export const delRightGroup = (params) =>
  http.delete("/v1/Auth/DelRightGroup", params);

// 获取所有权限分组列表
export const getAllRightGroup = (params) =>
  http.get("/v1/Auth/GetRightGroupList", params);

// 获取权限分组信息
export const getRightGroupInfo = (params) =>
  http.get("/v1/Auth/GtRightGroupInfoById", params);

/**************************** 角色操作 ****************************/

// 创建新角色
export const createNewRole = (params) =>
  http.post("/v1/Auth/CreateRole", params);

// 修改角色
export const updateRole = (params) => http.put("/v1/Auth/UpdateRole", params);

// 删除角色
export const delRole = (params) => http.delete("/v1/Auth/DelRole", params);

// 获取角色信息
export const getRoleInfo = (params) =>
  http.get("/v1/Auth/GetRoleInfoById", params);

// 获取角色表格
export const getRoleTable = (params) =>
  http.get("/v1/Auth/GetRoleTable", params);

/**************************** 用户组操作 ****************************/

// 创建新用户组
export const createNewGroup = (params) =>
  http.post("/v1/Auth/CreateGroup", params);

// 修改用户组
export const updateGroup = (params) => http.put("/v1/Auth/UpdateGroup", params);

// 删除用户组
export const delGroup = (params) => http.delete("/v1/Auth/DelGroup", params);

// 获取用户组信息
export const getGroupInfo = (params) =>
  http.get("/v1/Auth/GetGroupInfoById", params);

// 获取用户组列表
export const getAllGroupList = (params) =>
  http.get("/v1/Auth/GetGroupList", params);

// 获取用户组表格
export const getGroupTable = (params) =>
  http.get("/v1/Auth/GetGroupTable", params);
